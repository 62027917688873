import React from 'react';

import PropTypes from 'prop-types';

export default function CaretDown({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M31 9.748L16.7067 24.04C16.6139 24.1329 16.5038 24.2066 16.3825 24.2569C16.2612 24.3071 16.1313 24.333 16 24.333C15.8687 24.333 15.7388 24.3071 15.6175 24.2569C15.4962 24.2066 15.3861 24.1329 15.2933 24.04L1 9.748"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CaretDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
